import { Suspense, lazy, LazyExoticComponent } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteObject } from 'react-router';
import SuspenseLoader from '../components/SuspenseLoader/SuspenseLoader';
import {
  HOME_PAGE_PATH,
  PAGE_PATH,
  SEARCH_PAGE_PATH,
  STATIC_INDICATIONS_PATH,
  STATUS_PATH
} from '../constants/route';
import AppLayout from '../layout/AppLayout';
import Status404 from '../pages/Status/Status404/Status404';

import statusRoutes from './statusRoutes';

const Loader =
  (
    Component: LazyExoticComponent<() => JSX.Element> | JSX.IntrinsicAttributes
  ) =>
  (props: JSX.IntrinsicAttributes) =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        {/* @ts-ignore */}
        <Component {...props} />
      </Suspense>
    );

const AssetsPage = Loader(lazy(() => import('../pages/Assets') as any));
const HomePage = Loader(lazy(() => import('../pages/Home') as any));
const IndicationsPage = Loader(
  lazy(() => import('../pages/Static/Indications') as any)
);

const router: RouteObject[] = [
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: STATUS_PATH,
    children: statusRoutes
  },
  {
    path: HOME_PAGE_PATH,
    element: (
      <AppLayout>
        <HomePage />
      </AppLayout>
    )
  },
  {
    path: SEARCH_PAGE_PATH,
    element: (
      <AppLayout>
        <AssetsPage />
      </AppLayout>
    )
  },
  {
    path: PAGE_PATH,
    element: (
      <AppLayout>
        <AssetsPage />
      </AppLayout>
    )
  },
  {
    path: STATIC_INDICATIONS_PATH,
    element: (
      <AppLayout>
        <IndicationsPage />
      </AppLayout>
    )
  }
];

export default router;
