import { Asset } from '../../../types/asset';
import {
  AggregationsType,
  AutocompleteType,
  TypeOnChangeValue
} from '../../../types/logic';

export const GET_ASSETS_START = 'GET_ASSETS_START';
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';

export const GET_MORE_ASSETS_START = 'GET_MORE_ASSETS_START';
export const GET_MORE_ASSETS_SUCCESS = 'GET_MORE_ASSETS_SUCCESS';
export const GET_MORE_ASSETS_FAILURE = 'GET_MORE_ASSETS_FAILURE';

export const FACET_FILTER_CHANGED = 'FACET_FILTER_CHANGED';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SEARCH_SUCCESS = 'SET_SEARCH_SUCCESS';
export const TOGGLE_ASSETS_DRAWER = 'TOGGLE_ASSETS_DRAWER';
export const SORT_BY = 'SORT_BY';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';

export type GET_ASSETS_START_ACTION = {
  type: typeof GET_ASSETS_START;
  payload: {};
};

export type GET_ASSETS_SUCCESS_ACTION = {
  type: typeof GET_ASSETS_SUCCESS;
  payload: {
    assets: Array<Asset>;
    facets: AggregationsType;
    totalCount: number;
    tieBreaker: Array<string> | null;
  };
};

export type GET_ASSETS_FAILURE_ACTION = {
  type: typeof GET_ASSETS_FAILURE;
  payload: {
    error: string;
  };
};

export type GET_MORE_ASSETS_START_ACTION = {
  type: typeof GET_MORE_ASSETS_START;
  payload: {};
};

export type GET_MORE_ASSETS_SUCCESS_ACTION = {
  type: typeof GET_MORE_ASSETS_SUCCESS;
  payload: {
    assets: Array<Asset>;
    facets: AggregationsType;
    totalCount: number;
    tieBreaker: Array<string> | null;
  };
};

export type GET_MORE_ASSETS_FAILURE_ACTION = {
  type: typeof GET_MORE_ASSETS_FAILURE;
  payload: {
    error: string;
  };
};

export type FACET_FILTER_CHANGED_ACTION = {
  type: typeof FACET_FILTER_CHANGED;
  payload: {
    facet: string;
    value: TypeOnChangeValue | TypeOnChangeValue[];
    resetAllFiltersBefore: boolean;
  };
};

export type RESET_FILTERS_ACTION = {
  type: typeof RESET_FILTERS;
  payload: {};
};

export type RESET_FILTER_ACTION = {
  type: typeof RESET_FILTER;
  payload: { facet: string; value: string };
};

export type SET_SEARCH_ACTION = {
  type: typeof SET_SEARCH;
  payload: { combinedSearch: string | undefined };
};

export type SET_SEARCH_SUCCESS_ACTION = {
  type: typeof SET_SEARCH_SUCCESS;
  payload: {};
};

export type TOGGLE_ASSETS_DRAWER_ACTION = {
  type: typeof TOGGLE_ASSETS_DRAWER;
  payload: {};
};

export type SORT_BY_ACTION = {
  type: typeof SORT_BY;
  payload: {
    sortBy: AutocompleteType;
  };
};

export type SET_HEADER_HEIGHT_ACTION = {
  type: typeof SET_HEADER_HEIGHT;
  payload: { headerHeight: number };
};

export function getAssets(): GET_ASSETS_START_ACTION {
  return {
    type: GET_ASSETS_START,
    payload: {}
  };
}

export function getAssetsSuccess(
  assets: Array<Asset>,
  totalCount: number,
  facets: AggregationsType,
  tieBreaker: Array<string> | null
): GET_ASSETS_SUCCESS_ACTION {
  return {
    type: GET_ASSETS_SUCCESS,
    payload: { assets, facets, totalCount, tieBreaker }
  };
}

export function getAssetsFailed(error: string): GET_ASSETS_FAILURE_ACTION {
  return {
    type: GET_ASSETS_FAILURE,
    payload: { error }
  };
}

export function getMoreAssets(): GET_MORE_ASSETS_START_ACTION {
  return {
    type: GET_MORE_ASSETS_START,
    payload: {}
  };
}

export function getMoreAssetsSuccess(
  assets: Array<Asset>,
  totalCount: number,
  facets: AggregationsType,
  tieBreaker: Array<string> | null
): GET_MORE_ASSETS_SUCCESS_ACTION {
  return {
    type: GET_MORE_ASSETS_SUCCESS,
    payload: { assets, facets, totalCount, tieBreaker }
  };
}

export function getMoreAssetsFailed(
  error: string
): GET_MORE_ASSETS_FAILURE_ACTION {
  return {
    type: GET_MORE_ASSETS_FAILURE,
    payload: { error }
  };
}

export function facetFilterChanged(
  facet: string,
  value: TypeOnChangeValue | TypeOnChangeValue[],
  resetAllFiltersBefore: boolean = false
): FACET_FILTER_CHANGED_ACTION {
  return {
    type: FACET_FILTER_CHANGED,
    payload: { facet, value, resetAllFiltersBefore }
  };
}

export function resetFilters(): RESET_FILTERS_ACTION {
  return {
    type: RESET_FILTERS,
    payload: {}
  };
}

export function resetFilter(facet: string, value: string): RESET_FILTER_ACTION {
  return {
    type: RESET_FILTER,
    payload: { facet, value }
  };
}

export function setSearch(
  combinedSearch: string | undefined
): SET_SEARCH_ACTION {
  return {
    type: SET_SEARCH,
    payload: { combinedSearch }
  };
}

export function setSearchSuccess(): SET_SEARCH_SUCCESS_ACTION {
  return {
    type: SET_SEARCH_SUCCESS,
    payload: {}
  };
}

export function toggleAssetsDrawer(): TOGGLE_ASSETS_DRAWER_ACTION {
  return {
    type: TOGGLE_ASSETS_DRAWER,
    payload: {}
  };
}

export function sortBy(sortBy: AutocompleteType): SORT_BY_ACTION {
  return {
    type: SORT_BY,
    payload: { sortBy }
  };
}

export function setHeaderHeight(
  headerHeight: number
): SET_HEADER_HEIGHT_ACTION {
  return {
    type: SET_HEADER_HEIGHT,
    payload: { headerHeight }
  };
}

export type ActionTypes =
  | GET_ASSETS_START_ACTION
  | GET_ASSETS_SUCCESS_ACTION
  | GET_ASSETS_FAILURE_ACTION
  | GET_MORE_ASSETS_START_ACTION
  | GET_MORE_ASSETS_SUCCESS_ACTION
  | GET_MORE_ASSETS_FAILURE_ACTION
  | FACET_FILTER_CHANGED_ACTION
  | RESET_FILTERS_ACTION
  | RESET_FILTER_ACTION
  | SET_SEARCH_ACTION
  | SET_SEARCH_SUCCESS_ACTION
  | TOGGLE_ASSETS_DRAWER_ACTION
  | SORT_BY_ACTION
  | SET_HEADER_HEIGHT_ACTION;
