import '../../styles/slick.scss';
import '../../styles/slick-theme.scss';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { FC } from 'react';
import MyTheme from '../../theme/MyTheme';

import { BrowserRouter } from 'react-router-dom';
import ContentRouter from './ContentRouter';

const App: FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={MyTheme}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          <ContentRouter />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
