export const GET_FILE_URL_START = 'GET_FILE_URL_START';
export const GET_FILE_URL_FAILURE = 'GET_FILE_URL_FAILURE';
export const GET_FILE_URL_SUCCESS = 'GET_FILE_URL_SUCCESS';

export const GET_DOWNLOAD_LINK_START = 'GET_DOWNLOAD_LINK_START';
export const GET_DOWNLOAD_LINK_FAILURE = 'GET_DOWNLOAD_LINK_FAILURE';
export const GET_DOWNLOAD_LINK_SUCCESS = 'GET_DOWNLOAD_LINK_SUCCESS';

export const GET_FILE_URL_BY_VERSION_START = 'GET_FILE_URL_BY_VERSION_START';
export const GET_FILE_URL_BY_VERSION_FAILURE =
  'GET_FILE_URL_BY_VERSION_FAILURE';
export const GET_FILE_URL_BY_VERSION_SUCCESS =
  'GET_FILE_URL_BY_VERSION_SUCCESS';

export const GET_PREVIEW_LINK_START = 'GET_PREVIEW_LINK_START';
export const GET_PREVIEW_LINK_FAILURE = 'GET_PREVIEW_LINK_FAILURE';
export const GET_PREVIEW_LINK_SUCCESS = 'GET_PREVIEW_LINK_SUCCESS';

export type GET_FILE_URL_START_ACTION = {
  type: typeof GET_FILE_URL_START;
  payload: {
    id: string;
    version: string | number | null;
    key: string | undefined;
  };
};

export type GET_FILE_URL_SUCCESS_ACTION = {
  type: typeof GET_FILE_URL_SUCCESS;
  payload: {
    url: string;
  };
};

export type GET_FILE_URL_FAILURE_ACTION = {
  type: typeof GET_FILE_URL_FAILURE;
  payload: {
    error: string;
  };
};

export function getFileUrl(
  id: string,
  version: number | null,
  key?: string | undefined
): GET_FILE_URL_START_ACTION {
  return {
    type: GET_FILE_URL_START,
    payload: { id, version, key }
  };
}

export function getFileUrlSuccess(url: string): GET_FILE_URL_SUCCESS_ACTION {
  return {
    type: GET_FILE_URL_SUCCESS,
    payload: { url }
  };
}

export function getFileUrlFailed(error: string): GET_FILE_URL_FAILURE_ACTION {
  return {
    type: GET_FILE_URL_FAILURE,
    payload: { error }
  };
}

export type GET_DOWNLOAD_LINK_START_ACTION = {
  type: typeof GET_DOWNLOAD_LINK_START;
  payload: { id: string | number; assetVersion: number | null };
};

export type GET_DOWNLOAD_LINK_SUCCESS_ACTION = {
  type: typeof GET_DOWNLOAD_LINK_SUCCESS;
  payload: {
    url: string;
  };
};

export type GET_DOWNLOAD_LINK_FAILURE_ACTION = {
  type: typeof GET_DOWNLOAD_LINK_FAILURE;
  payload: {
    error: string;
  };
};

export function getDownloadLink(
  id: string | number,
  assetVersion: number | null
): GET_DOWNLOAD_LINK_START_ACTION {
  return {
    type: GET_DOWNLOAD_LINK_START,
    payload: { id, assetVersion }
  };
}

export function getDownloadLinkSuccess(
  url: string
): GET_DOWNLOAD_LINK_SUCCESS_ACTION {
  return {
    type: GET_DOWNLOAD_LINK_SUCCESS,
    payload: { url }
  };
}

export function getDownloadLinkFailed(
  error: string
): GET_DOWNLOAD_LINK_FAILURE_ACTION {
  return {
    type: GET_DOWNLOAD_LINK_FAILURE,
    payload: { error }
  };
}

export type GET_FILE_URL_BY_VERSION_START_ACTION = {
  type: typeof GET_FILE_URL_BY_VERSION_START;
  payload: { id: string; version: number };
};

export type GET_FILE_URL_BY_VERSION_SUCCESS_ACTION = {
  type: typeof GET_FILE_URL_BY_VERSION_SUCCESS;
  payload: {
    id: string;
    version: number;
    url: string;
  };
};

export type GET_FILE_URL_BY_VERSION_FAILURE_ACTION = {
  type: typeof GET_FILE_URL_BY_VERSION_FAILURE;
  payload: {
    error: string;
  };
};

export function getFileUrlByVersion(
  id: string,
  version: number
): GET_FILE_URL_BY_VERSION_START_ACTION {
  return {
    type: GET_FILE_URL_BY_VERSION_START,
    payload: { id, version }
  };
}

export function getFileUrlByVersionSuccess(
  id: string,
  version: number,
  url: string
): GET_FILE_URL_BY_VERSION_SUCCESS_ACTION {
  return {
    type: GET_FILE_URL_BY_VERSION_SUCCESS,
    payload: { id, version, url }
  };
}

export function getFileUrlByVersionFailed(
  error: string
): GET_FILE_URL_BY_VERSION_FAILURE_ACTION {
  return {
    type: GET_FILE_URL_BY_VERSION_FAILURE,
    payload: { error }
  };
}

export type GET_PREVIEW_LINK_START_ACTION = {
  type: typeof GET_PREVIEW_LINK_START;
  payload: { id: string | number; assetVersion: number | null };
};

export type GET_PREVIEW_LINK_SUCCESS_ACTION = {
  type: typeof GET_PREVIEW_LINK_SUCCESS;
  payload: {
    url: string;
  };
};

export type GET_PREVIEW_LINK_FAILURE_ACTION = {
  type: typeof GET_PREVIEW_LINK_FAILURE;
  payload: {
    error: string;
  };
};

export function getPreviewLink(
  id: string | number,
  assetVersion: number | null
): GET_PREVIEW_LINK_START_ACTION {
  return {
    type: GET_PREVIEW_LINK_START,
    payload: { id, assetVersion }
  };
}

export function getPreviewLinkSuccess(
  url: string
): GET_PREVIEW_LINK_SUCCESS_ACTION {
  return {
    type: GET_PREVIEW_LINK_SUCCESS,
    payload: { url }
  };
}

export function getPreviewLinkFailed(
  error: string
): GET_PREVIEW_LINK_FAILURE_ACTION {
  return {
    type: GET_PREVIEW_LINK_FAILURE,
    payload: { error }
  };
}
export type ActionTypes =
  | GET_FILE_URL_START_ACTION
  | GET_FILE_URL_SUCCESS_ACTION
  | GET_FILE_URL_FAILURE_ACTION
  | GET_DOWNLOAD_LINK_START_ACTION
  | GET_DOWNLOAD_LINK_SUCCESS_ACTION
  | GET_DOWNLOAD_LINK_FAILURE_ACTION
  | GET_FILE_URL_BY_VERSION_START_ACTION
  | GET_FILE_URL_BY_VERSION_SUCCESS_ACTION
  | GET_FILE_URL_BY_VERSION_FAILURE_ACTION
  | GET_PREVIEW_LINK_START_ACTION
  | GET_PREVIEW_LINK_SUCCESS_ACTION
  | GET_PREVIEW_LINK_FAILURE_ACTION;
