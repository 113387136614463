import { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../i18n/i18n';
import { enumLanguage } from '../../types/enumeration';
import { StateType } from '../../redux/reducers/store';
import { getConfig } from '../../redux/actions/config/getConfigAction';
import { STATUS_SUCCESS } from '../../types/status';

const ConfigHandler: FC = ({ children }) => {
  const dispatch = useDispatch();

  const statusConfig = useSelector((state: StateType) => state.config.status);
  useEffect(() => {
    i18n.changeLanguage(enumLanguage.en);
    if (statusConfig !== STATUS_SUCCESS) {
      dispatch(getConfig());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default ConfigHandler;
