import Spinner from './Spinner';
import MyTheme, { PRIMARY_COLOR } from '../../theme/MyTheme';
import { Box, SxProps, Theme } from '@mui/material';
import { FC } from 'react';

const sxStyles: Record<string, SxProps<Theme>> = {
  spinnerCell: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: MyTheme.spacing(5),
    paddingBottom: MyTheme.spacing(5)
  }
};

const SpinnerContainer: FC = () => {
  return (
    <Box sx={sxStyles.spinnerCell}>
      <Spinner fontSize={40} borderWidth={4} color={PRIMARY_COLOR} />
    </Box>
  );
};

export default SpinnerContainer;
