export const getLocalStorage = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage;
  }
  return null;
};

export const getLocation = () => {
  if (typeof window !== 'undefined') {
    return window.location;
  }
  return null;
};

export const getHistory = () => {
  if (typeof window !== 'undefined') {
    return window.history;
  }
  return null;
};
