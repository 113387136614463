import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const getDrawerItems1ByRole = () =>
  [] as {
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    action: () => void;
    pathName: string;
  }[];

export const getDrawerItems2ByRole = () =>
  [] as {
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    action: () => void;
    pathName: string;
  }[];

export const getDrawerItemsByRole = () => {
  const drawerItems1 = getDrawerItems1ByRole();
  const drawerItems2 = getDrawerItems2ByRole();
  return { drawerItems1, drawerItems2 };
};
