const frJSON = {
  decathlon_show_filters: 'Afficher les filtres',
  decathlon_hide_filters: 'Cacher les filtres',
  decathlon_reset: 'Effacer',
  decathlon_damAssetCategory_label: 'Catégories',
  decathlon_damPresetView_label: 'Preset View',
  decathlon_topics_label: 'Topics',
  decathlon_rights_label: 'Droits',
  decathlon_domain_label: 'Domaine responsable',
  decathlon_provenance_label: 'Provenance flow',
  decathlon_itemType_label: 'Item type',
  decathlon_sport_label: 'Sports',
  decathlon_brand_label: 'Brand',
  decathlon_productLevel1_label: 'Product level 1',
  decathlon_productLevel2_label: 'Product level 2',
  decathlon_productLevel3_label: 'Product level 3',
  decathlon_productNature_label: 'Product Natures',
  decathlon_process_label: 'Process',
  decathlon_componentLevel1_label: 'Component level 1',
  decathlon_componentLevel2_label: 'Component level 2',
  decathlon_componentNature_label: 'Component nature',
  decathlon_language_label: 'Languages',
  decathlon_audience_label: 'Audience',
  decathlon_media_label: 'Media',
  decathlon_selected_filters_label: 'Filtres sélectionnés',
  decathlon_clear_all_filters: 'Supprimer les filtres sélectionnées',
  decathlon_no_matches: 'Nous sommes tristes, rien ne correspond',
  decathlon_try_another_search: 'Veuillez essayer une autre recherche...',
  decathlon_autocomplete_no_options_text: "Pas d'options disponibles",
  decathlon_autocomplete_loading_text: 'Chargement...',
  decathlon_menu_tooltip: 'Menu',
  decathlon_scroll_to_see_more: "Scroller vers le bas pour voir plus d'items",
  decathlon_go_back_top: 'Top',
  decathlon_sortBy_label: 'Trier par',
  decathlon_sort_newest_label: 'Nouveauté',
  decathlon_sort_oldest_label: 'Ancienneté',
  decathlon_sort_name_a_to_z_label: 'Nom : A à Z',
  decathlon_sort_name_z_to_a_label: 'Nom : Z à A',
  decathlon_sort_revelance_label: 'Pertinence',
  decathlon_archives_label: 'Archives',
  decathlon_actions_label: 'Actions',
  decathlon_file_informations_label: 'Informations du fichier',
  decathlon_content_informations_label: 'Informations détaillées',
  decathlon_createdAt_label: 'Date de création',
  decathlon_name_label: 'Nom du document',
  decathlon_open_label: 'Ouvrir',
  decathlon_download_label: 'Télécharger',
  decathlon_go_back_label: 'Précédent',
  decathlon_archived_label: 'ARCHIVE',
  decathlon_master_label: 'Master',
  decathlon_search_label: 'Rechercher…',
  decathlon_results_sentence: 'ont été trouvé',
  decathlon_topic_label: 'Topic',
  decathlon_publisher_label: 'Publié par',
  decathlon_status_label: 'Statut',
  decathlon_internalStatus_label: 'Status interne',
  decathlon_modifiedAt_label: 'Date de modification',
  decathlon_productModel_label: 'Product Models',
  decathlon_height_label: 'Height',
  decathlon_width_label: 'Width',
  decathlon_creator_label: 'Créateur',
  decathlon_productSuperModelId_label: 'Product SuperModels',
  decathlon_componentModel_label: 'Component model',
  decathlon_componentId_label: 'Component ID',
  decathlon_contentId_label: 'Content ID',
  decathlon_componentSuperModel_label: 'Component super model',
  decathlon_accessRights_label: 'Access rights',
  decathlon_publicUrl_label: 'Public url',
  decathlon_urlKameleon_label: 'Url kameleon',
  decathlon_filename_label: 'Titre',
  decathlon_file_subtitle_label: 'FICHIER',
  decathlon_linked_products_subtitle_label: 'PRODUITS LIES',
  decathlon_version_label: 'Version',
  decathlon_status_page_doesnt_exist:
    "La page que vous recherchez n'existe pas.",
  decathlon_status_go_homepage: "Aller à la page d'accueil",
  decathlon_home_page_title_label:
    'Retrouver un marquage, une indication concernant votre produit',
  decathlon_home_page_sub_title_label:
    'Le site des marquages produit pour retrouver une indication, une consigne de tri, un certificat',
  decathlon_home_page_search_form_label: 'Saisissez votre recherche',
  decathlon_home_page_search_form_placeholder_label:
    'Entrez le nom de votre produit, code modèle de votre produit...',
  decathlon_menu_item: 'Comprendre les indications',
  decathlon_open_error_label:
    "Une erreur s'est produite lors de l'ouverture du document error"
};

export default frJSON;
