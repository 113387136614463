import { Box, useTheme } from '@mui/material';
import SpinnerContainer from '../spinner/SpinnerContainer';

function SuspenseLoader() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: '100%'
      }}
    >
      <SpinnerContainer />
    </Box>
  );
}

export default SuspenseLoader;
