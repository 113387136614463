export const DECATHLON_BLUE_COLOR = '#133E57';
export const DECATHLON_LIGHT_GREEN_COLOR = '#97c006';
export const DECATHLON_BROWN_COLOR = '#D1752E';
export const DECATHLON_LIGHT_BROWN_COLOR = '#EB905B';

export const DECATHLON_GREY_COLOR = '#F7F7F7';
export const DECATHLON_DARK_GREY_COLOR = '#858585';

export const COLOR_RED = 'red';
export const COLOR_BLACK = '#000000DE';
export const COLOR_WHITE = 'white';
