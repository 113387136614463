import { Box } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../redux/reducers/store';
import SpinnerContainer from './spinner/SpinnerContainer';

const sxStyles = {
  container: ({ headerHeight }: { headerHeight: number }) => ({
    marginTop: `${headerHeight}px`
  })
};

const Loading: FC = () => {
  const headerHeight = useSelector(
    (state: StateType) => state.assets.headerHeight
  );
  return (
    <Box sx={sxStyles.container({ headerHeight })}>
      <SpinnerContainer />
    </Box>
  );
};

export default Loading;
