import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  valuekey: string;
  useTranslate?: boolean;
};

const Text: FC<Props> = ({ valuekey, useTranslate = true }) => {
  const { t } = useTranslation();
  return <>{useTranslate ? t(valuekey) : valuekey}</>;
};

export default Text;
