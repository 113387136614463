import { combineReducers } from 'redux';
import assetsReducer from './assets/assetsReducer';
import assetReducer from './asset/assetReducer';
import configReducer from './config/configReducer';

export enum StateAccessorType {
  ASSETS_REDUCER = 'assets',
  ASSET_REDUCER = 'asset',
  CONFIG_REDUCER = 'config'
}

export const reducers = {
  [StateAccessorType.ASSETS_REDUCER]: assetsReducer,
  [StateAccessorType.ASSET_REDUCER]: assetReducer,
  [StateAccessorType.CONFIG_REDUCER]: configReducer
};

export default combineReducers(reducers);
