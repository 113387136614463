import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getConfigFailed,
  getConfigSuccess,
  GET_CONFIG_START_ACTION
} from '../actions/config/getConfigAction';
import * as configService from '../../services/config/configService';

import { GET_CONFIG_START } from '../actions/config/getConfigAction';
import { sortBy } from '../actions/assets/assetsActions';
import get from 'lodash/get';
import { Config /*  WebAppConfig  */ } from '../../types/logic';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* import myConfig from '../../constants/config.json'; */

function* getConfig(action: GET_CONFIG_START_ACTION): any {
  try {
    let config: Config = yield call(configService.getConfig);

    /*     config = { ...config, webapp: myConfig as unknown as WebAppConfig }; */

    const sortByDefaultValue = get(config, ['webapp', 'sortBy', 0])
      ? {
          label: config.webapp.sortBy[0].label,
          value: config.webapp.sortBy[0].label
        }
      : null;

    yield put(getConfigSuccess(config));
    if (sortByDefaultValue) {
      yield put(sortBy(sortByDefaultValue));
    }
  } catch (e: unknown) {
    yield put(getConfigFailed((e as { message: string })?.message));
  }
}

export default function* facetSaga() {
  yield takeLatest(GET_CONFIG_START, getConfig);
}
