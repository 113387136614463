import { FC, Suspense } from 'react';

import Header from '../header/Header';
import Loading from '../components/Loading';

const AppLayout: FC = ({ children }) => {
  return (
    <>
      <Header />
      <Suspense fallback={<Loading />}>{children}</Suspense>
      {/*      <Footer /> */}
    </>
  );
};

export default AppLayout;
