import { makeStyles } from '@mui/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  SxProps,
  Theme
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { COLOR_WHITE, DECATHLON_LIGHT_GREEN_COLOR } from '../constants/colors';
import MyTheme from '../theme/MyTheme';
import { HOME_PAGE_PATH } from '../constants/route';
import { redirectTo } from '../utils/utils';
import { FC } from 'react';

const sxStyles: Record<string, SxProps<Theme>> = {
  list: {
    width: 300
  },
  containerImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: MyTheme.spacing(2),
    paddingBottom: MyTheme.spacing(2)
  },
  isSelected: {
    backgroundColor: MyTheme.palette.primary.main,
    color: COLOR_WHITE,
    '&:hover': {
      backgroundColor: MyTheme.palette.primary.main
    }
  },
  listItem: {
    '&:hover': {
      backgroundColor: DECATHLON_LIGHT_GREEN_COLOR,
      color: COLOR_WHITE,
      '& $listItemIconHover': {
        color: `${COLOR_WHITE} !important`
      }
    }
  },
  // DO NOT REMOVE EMPTY CLASS
  listItemIconHover: {},
  isIconSelected: {
    color: COLOR_WHITE
  }
};

const useStyles = makeStyles({
  image: { width: 150, cursor: 'pointer' }
});

type Props = {
  closeDrawer: () => void;
  isOpenDrawer: boolean;
  drawerItemsTop: Array<{
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    pathName: string;
    action?: () => void | Promise<boolean>;
  }>;
  drawerItemsBottom: Array<{
    text: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    pathName: string;
    action?: () => void | Promise<boolean>;
  }>;
};

const DrawerMenu: FC<Props> = ({
  closeDrawer,
  isOpenDrawer,
  drawerItemsTop,
  drawerItemsBottom
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isOpenDrawer}
      onClose={closeDrawer}
    >
      <Box
        sx={sxStyles.list}
        role="presentation"
        onClick={closeDrawer}
        onKeyDown={closeDrawer}
      >
        <Box sx={sxStyles.containerImage}>
          <img
            alt="Logo DECATHLON"
            src={`${process.env.PUBLIC_URL}/ShareLogo.svg`}
            className={classes.image}
            onClick={redirectTo(navigate, HOME_PAGE_PATH)}
          />
        </Box>
        <List>
          {drawerItemsTop.map((drawerItem, index) => {
            const Icon = drawerItem.icon;
            return (
              <ListItem
                button
                key={index}
                onClick={drawerItem.action}
                sx={
                  location?.pathname === drawerItem.pathName
                    ? sxStyles.isSelected
                    : sxStyles.listItem
                }
              >
                <ListItemIcon sx={sxStyles.listItemIconHover}>
                  <Icon
                    sx={
                      location?.pathname === drawerItem.pathName
                        ? sxStyles.isIconSelected
                        : {}
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={drawerItem.text} />
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <List>
          {drawerItemsBottom.map((drawerItem, index) => {
            const Icon = drawerItem.icon;
            return (
              <ListItem
                button
                key={index}
                onClick={drawerItem.action}
                sx={
                  location?.pathname === drawerItem.pathName
                    ? sxStyles.isSelected
                    : sxStyles.listItem
                }
              >
                <ListItemIcon sx={sxStyles.listItemIconHover}>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={drawerItem.text} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
