import { Asset } from '../../../types/asset';
import { AggregationsType, AutocompleteType } from '../../../types/logic';
import {
  Status,
  STATUS_FAILURE,
  STATUS_LOADING,
  STATUS_START,
  STATUS_SUCCESS
} from '../../../types/status';
import {
  onChangeFacets,
  onResetFilter,
  onResetFilters
} from '../../../utils/utils';

import {
  ActionTypes,
  FACET_FILTER_CHANGED,
  GET_ASSETS_FAILURE,
  GET_ASSETS_START,
  GET_ASSETS_SUCCESS,
  GET_MORE_ASSETS_FAILURE,
  GET_MORE_ASSETS_START,
  GET_MORE_ASSETS_SUCCESS,
  RESET_FILTER,
  RESET_FILTERS,
  SET_HEADER_HEIGHT,
  SET_SEARCH,
  SET_SEARCH_SUCCESS,
  SORT_BY,
  TOGGLE_ASSETS_DRAWER
} from '../../actions/assets/assetsActions';

export type State = {
  assets: Array<Asset>;
  facets: AggregationsType;
  sortBy: AutocompleteType | null;
  status: Status;
  combinedSearch: string | undefined;
  totalCount: number;
  tieBreaker: Array<string> | null;
  error: null | string;
  open: boolean;
  headerHeight: number;
  setSearchStatus: Status;
};

const initialState: State = {
  assets: [],
  facets: {},
  sortBy: null,
  combinedSearch: undefined,
  totalCount: 0,
  status: STATUS_START,
  tieBreaker: [],
  error: null,
  open: true,
  headerHeight: 0,
  setSearchStatus: STATUS_START
};

// eslint-disable-next-line func-names
// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state: State = initialState,
  action: ActionTypes
): State {
  switch (action.type) {
    case GET_ASSETS_START:
      return {
        ...state,
        status: STATUS_LOADING,
        assets: initialState.assets,
        facets: state.facets,
        error: initialState.error,
        tieBreaker: initialState.tieBreaker,
        totalCount: initialState.totalCount
      };
    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        assets: action.payload.assets,
        facets: action.payload.facets,
        totalCount: action.payload.totalCount,
        tieBreaker: action.payload.tieBreaker
      };
    case GET_ASSETS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        status: STATUS_FAILURE
      };
    case GET_MORE_ASSETS_START:
      return { ...state, status: STATUS_LOADING };
    case GET_MORE_ASSETS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        assets: [...state.assets, ...action.payload.assets],
        facets: action.payload.facets,
        totalCount: action.payload.totalCount,
        tieBreaker: action.payload.tieBreaker
      };
    case GET_MORE_ASSETS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        status: STATUS_FAILURE
      };

    case FACET_FILTER_CHANGED:
      return {
        ...state,
        status: STATUS_LOADING,
        assets: initialState.assets,
        error: initialState.error,
        tieBreaker: initialState.tieBreaker,
        totalCount: initialState.totalCount,
        facets: onChangeFacets(
          action.payload.resetAllFiltersBefore
            ? onResetFilters(state.facets)
            : state.facets,
          action.payload.facet,
          action.payload.value
        )
      };
    case RESET_FILTERS:
      return {
        ...state,
        status: STATUS_LOADING,
        assets: initialState.assets,
        error: initialState.error,
        tieBreaker: initialState.tieBreaker,
        totalCount: initialState.totalCount,
        facets: onResetFilters(state.facets)
      };
    case RESET_FILTER:
      return {
        ...state,
        status: STATUS_LOADING,
        assets: initialState.assets,
        error: initialState.error,
        tieBreaker: initialState.tieBreaker,
        totalCount: initialState.totalCount,
        facets: onResetFilter(
          state.facets,
          action.payload.facet,
          action.payload.value
        )
      };
    case SET_SEARCH:
      return {
        ...state,
        status: STATUS_LOADING,
        assets: initialState.assets,
        error: initialState.error,
        tieBreaker: initialState.tieBreaker,
        totalCount: initialState.totalCount,
        combinedSearch: action.payload.combinedSearch,
        setSearchStatus: STATUS_LOADING
      };
    case SET_SEARCH_SUCCESS:
      return {
        ...state,
        setSearchStatus: STATUS_SUCCESS
      };
    case TOGGLE_ASSETS_DRAWER:
      return {
        ...state,
        open: !state.open
      };
    case SORT_BY:
      return {
        ...state,
        status: STATUS_LOADING,
        assets: initialState.assets,
        error: initialState.error,
        tieBreaker: initialState.tieBreaker,
        totalCount: initialState.totalCount,
        sortBy: action.payload.sortBy
      };
    case SET_HEADER_HEIGHT:
      return {
        ...state,
        headerHeight: action.payload.headerHeight
      };

    default:
      return state;
  }
}
