import { useRoutes } from 'react-router-dom';
import { setTokenSessionStorage } from '../../auth/session';
import ConfigHandler from './ConfigHandler';
import router from '../../router/router';

function ContentRouter() {
  const content = useRoutes(router);
  const token = String(process.env.REACT_APP_ACCESS_TOKEN_KEY);
  setTokenSessionStorage(token);

  return <ConfigHandler>{content}</ConfigHandler>;
}
export default ContentRouter;
