import { createTheme } from '@mui/material';
import {
  DECATHLON_BLUE_COLOR,
  DECATHLON_BROWN_COLOR
} from '../constants/colors';

export const PRIMARY_COLOR = DECATHLON_BROWN_COLOR;
export const SECONDARY_COLOR = DECATHLON_BLUE_COLOR;

export default createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    }
  },
  spacing: 8
});
