import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  FACET_FILTER_CHANGED,
  FACET_FILTER_CHANGED_ACTION,
  getAssetsFailed,
  getAssetsSuccess,
  getMoreAssetsFailed,
  getMoreAssetsSuccess,
  GET_ASSETS_START,
  GET_ASSETS_START_ACTION,
  GET_MORE_ASSETS_START,
  RESET_FILTER,
  RESET_FILTERS,
  RESET_FILTERS_ACTION,
  RESET_FILTER_ACTION,
  setSearch as setSearchAction,
  SET_SEARCH,
  SET_SEARCH_ACTION,
  SORT_BY,
  SORT_BY_ACTION
} from '../actions/assets/assetsActions';
import * as assetService from '../../services/asset/assetService';
import { StateType } from '../reducers/store';
import {
  buildQuery,
  getFacetsFromAggregations,
  getInitialFacets
} from '../../utils/utils';
import {
  AggregationsType,
  AutocompleteType,
  Config,
  ResponseAssets,
  SortBy
} from '../../types/logic';
import get from 'lodash/get';

function* getAssets(action: GET_ASSETS_START_ACTION): any {
  try {
    const {
      config,
      defaultSortBy,
      sortBy,
      facets,
      combinedSearch
    }: {
      config: Config;
      defaultSortBy: SortBy;
      sortBy: AutocompleteType;
      facets: AggregationsType;
      combinedSearch: string | undefined;
    } = yield select((state: StateType) => ({
      config: state.config.config,
      defaultSortBy: state.config.defaultSortBy,
      sortBy: state.assets.sortBy,
      facets: state.assets.facets,
      combinedSearch: state.assets.combinedSearch
    }));

    const currentSort = (config as Config).webapp.sortBy.find(
      (e) => e.label === sortBy.value
    );

    const sort = currentSort ? [currentSort?.sort] : [defaultSortBy];

    const initialFacets = getInitialFacets(
      [...config.webapp.topFilters, ...config.webapp.sidebarFilters],
      facets
    );

    const query = buildQuery(
      {
        term: combinedSearch
      },
      initialFacets,
      sort as Array<{ [x: string]: 'asc' | 'desc' }>,
      null,
      config.webapp
    );

    const responseAssets: ResponseAssets = yield call(
      assetService.getAssets,
      query
    );

    const hits = responseAssets.hits.hits;
    const newTieBreaker = get(hits, [hits.length - 1, 'sort'], null);

    const newFacets = getFacetsFromAggregations(
      facets,
      responseAssets.aggregations,
      config.webapp.languageFacet,
      config.webapp.languageZXX
    );

    yield put(
      getAssetsSuccess(
        hits,
        responseAssets.hits.total.value,
        newFacets,
        newTieBreaker
      )
    );
  } catch (e: unknown) {
    yield put(getAssetsFailed((e as { message: string })?.message));
  }
}

function* getMoreAssets(action: GET_ASSETS_START_ACTION): any {
  try {
    const {
      config,
      defaultSortBy,
      sortBy,
      tieBreaker,
      facets,
      combinedSearch
    }: {
      config: Config;
      defaultSortBy: SortBy;
      sortBy: AutocompleteType;
      tieBreaker: Array<string>;
      facets: AggregationsType;
      combinedSearch: string | undefined;
    } = yield select((state: StateType) => ({
      config: state.config.config,
      defaultSortBy: state.config.defaultSortBy,
      sortBy: state.assets.sortBy,
      tieBreaker: state.assets.tieBreaker,
      facets: state.assets.facets,
      combinedSearch: state.assets.combinedSearch
    }));

    const currentSort = config.webapp.sortBy.find(
      (e) => e.label === sortBy.value
    );
    const sort = currentSort ? [currentSort?.sort] : [defaultSortBy];

    const initialFacets = getInitialFacets(
      [...config.webapp.topFilters, ...config.webapp.sidebarFilters],
      facets
    );

    const query = buildQuery(
      {
        term: combinedSearch
      },
      initialFacets,
      sort as Array<{ [x: string]: 'asc' | 'desc' }>,
      tieBreaker,
      config.webapp
    );

    const responseAssets: ResponseAssets = yield call(
      assetService.getAssets,
      query
    );

    const hits = responseAssets.hits.hits;
    const newTieBreaker = get(hits, [hits.length - 1, 'sort'], null);
    const newFacets = getFacetsFromAggregations(
      facets,
      responseAssets.aggregations,
      config.webapp.languageFacet,
      config.webapp.languageZXX
    );

    yield put(
      getMoreAssetsSuccess(
        hits,
        responseAssets.hits.total.value,
        newFacets,
        newTieBreaker
      )
    );
  } catch (e: unknown) {
    yield put(getMoreAssetsFailed((e as { message: string })?.message));
  }
}

function* resetFilter(action: RESET_FILTER_ACTION): any {
  try {
    const {
      config,
      defaultSortBy,
      sortBy,
      facets,
      combinedSearch
    }: {
      config: Config;
      defaultSortBy: SortBy;
      sortBy: AutocompleteType;
      facets: AggregationsType;
      combinedSearch: string | undefined;
    } = yield select((state: StateType) => ({
      config: state.config.config,
      defaultSortBy: state.config.defaultSortBy,
      sortBy: state.assets.sortBy,
      facets: state.assets.facets,
      combinedSearch: state.assets.combinedSearch
    }));

    const currentSort = config.webapp.sortBy.find(
      (e) => e.label === sortBy.value
    );
    const sort = currentSort ? [currentSort?.sort] : [defaultSortBy];

    const initialFacets = getInitialFacets(
      [...config.webapp.topFilters, ...config.webapp.sidebarFilters],
      facets
    );

    const query = buildQuery(
      {
        term: combinedSearch
      },
      initialFacets,
      sort as Array<{ [x: string]: 'asc' | 'desc' }>,
      null,
      config.webapp
    );

    const responseAssets: ResponseAssets = yield call(
      assetService.getAssets,
      query
    );

    const hits = responseAssets.hits.hits;
    const newTieBreaker = get(hits, [hits.length - 1, 'sort'], null);
    const newFacets = getFacetsFromAggregations(
      facets,
      responseAssets.aggregations,
      config.webapp.languageFacet,
      config.webapp.languageZXX
    );

    yield put(
      getAssetsSuccess(
        hits,
        responseAssets.hits.total.value,
        newFacets,
        newTieBreaker
      )
    );
  } catch (e: unknown) {
    yield put(getAssetsFailed((e as { message: string })?.message));
  }
}

function* resetFilters(action: RESET_FILTERS_ACTION): any {
  try {
    const {
      config,
      defaultSortBy,
      sortBy,
      tieBreaker,
      facets
    }: {
      config: Config;
      defaultSortBy: SortBy;
      sortBy: AutocompleteType;
      tieBreaker: Array<string>;
      facets: AggregationsType;
      combinedSearch: string | undefined;
    } = yield select((state: StateType) => ({
      config: state.config.config,
      defaultSortBy: state.config.defaultSortBy,
      sortBy: state.assets.sortBy,
      tieBreaker: state.assets.tieBreaker,
      facets: state.assets.facets
    }));

    const currentSort = config.webapp.sortBy.find(
      (e) => e.label === sortBy.value
    );
    const sort = currentSort ? [currentSort?.sort] : [defaultSortBy];

    const initialFacets = getInitialFacets(
      [...config.webapp.topFilters, ...config.webapp.sidebarFilters],
      facets
    );

    yield put(setSearchAction(undefined));

    const query = buildQuery(
      {
        term: undefined
      },
      initialFacets,
      sort as Array<{ [x: string]: 'asc' | 'desc' }>,
      tieBreaker,
      config.webapp
    );

    const responseAssets: ResponseAssets = yield call(
      assetService.getAssets,
      query
    );

    const hits = responseAssets.hits.hits;
    const newTieBreaker = get(hits, [hits.length - 1, 'sort'], null);
    const newFacets = getFacetsFromAggregations(
      facets,
      responseAssets.aggregations,
      config.webapp.languageFacet,
      config.webapp.languageZXX
    );
    yield put(
      getAssetsSuccess(
        hits,
        responseAssets.hits.total.value,
        newFacets,
        newTieBreaker
      )
    );
  } catch (e: unknown) {
    yield put(getAssetsFailed((e as { message: string })?.message));
  }
}

function* facetFilterChanged(action: FACET_FILTER_CHANGED_ACTION): any {
  try {
    const {
      config,
      defaultSortBy,
      sortBy,
      facets,
      combinedSearch
    }: {
      config: Config;
      defaultSortBy: SortBy;
      sortBy: AutocompleteType;
      facets: AggregationsType;
      combinedSearch: string | undefined;
    } = yield select((state: StateType) => ({
      config: state.config.config,
      defaultSortBy: state.config.defaultSortBy,
      sortBy: state.assets.sortBy,
      facets: state.assets.facets,
      combinedSearch: state.assets.combinedSearch
    }));

    const currentSort = config.webapp.sortBy.find(
      (e) => e.label === sortBy.value
    );
    const sort = currentSort ? [currentSort?.sort] : [defaultSortBy];

    const initialFacets = getInitialFacets(
      [...config.webapp.topFilters, ...config.webapp.sidebarFilters],
      facets
    );

    const query = buildQuery(
      {
        term: combinedSearch
      },
      initialFacets,
      sort as Array<{ [x: string]: 'asc' | 'desc' }>,
      null,
      config.webapp
    );

    const responseAssets: ResponseAssets = yield call(
      assetService.getAssets,
      query
    );

    const hits = responseAssets.hits.hits;
    const newTieBreaker = get(hits, [hits.length - 1, 'sort'], null);
    const newFacets = getFacetsFromAggregations(
      facets,
      responseAssets.aggregations,
      config.webapp.languageFacet,
      config.webapp.languageZXX
    );

    yield put(
      getAssetsSuccess(
        hits,
        responseAssets.hits.total.value,
        newFacets,
        newTieBreaker
      )
    );
  } catch (e: unknown) {
    yield put(getAssetsFailed((e as { message: string })?.message));
  }
}

function* setSearch(action: SET_SEARCH_ACTION): any {
  try {
    const {
      config,
      defaultSortBy,
      sortBy,
      facets,
      combinedSearch
    }: {
      config: Config;
      defaultSortBy: SortBy;
      sortBy: AutocompleteType;
      facets: AggregationsType;
      combinedSearch: string | undefined;
    } = yield select((state: StateType) => ({
      config: state.config.config,
      defaultSortBy: state.config.defaultSortBy,
      sortBy: state.assets.sortBy,
      facets: state.assets.facets,
      combinedSearch: state.assets.combinedSearch
    }));

    const currentSort = config.webapp.sortBy.find(
      (e) => e.label === sortBy.value
    );
    const sort = currentSort ? [currentSort?.sort] : [defaultSortBy];

    const initialFacets = getInitialFacets(
      [...config.webapp.topFilters, ...config.webapp.sidebarFilters],
      facets
    );

    const query = buildQuery(
      {
        term: combinedSearch
      },
      initialFacets,
      sort as Array<{ [x: string]: 'asc' | 'desc' }>,
      null,
      config.webapp
    );

    const responseAssets: ResponseAssets = yield call(
      assetService.getAssets,
      query
    );

    const hits = responseAssets.hits.hits;
    const newTieBreaker = get(hits, [hits.length - 1, 'sort'], null);
    const newFacets = getFacetsFromAggregations(
      facets,
      responseAssets.aggregations,
      config.webapp.languageFacet,
      config.webapp.languageZXX
    );

    yield put(
      getAssetsSuccess(
        hits,
        responseAssets.hits.total.value,
        newFacets,
        newTieBreaker
      )
    );
  } catch (e: unknown) {
    yield put(getAssetsFailed((e as { message: string })?.message));
  }
}

function* sortBy(action: SORT_BY_ACTION): any {
  try {
    const {
      config,
      defaultSortBy,
      sortBy,
      facets,
      combinedSearch
    }: {
      config: Config;
      defaultSortBy: SortBy;
      sortBy: AutocompleteType;
      facets: AggregationsType;
      combinedSearch: string | undefined;
    } = yield select((state: StateType) => ({
      config: state.config.config,
      defaultSortBy: state.config.defaultSortBy,
      sortBy: state.assets.sortBy,
      facets: state.assets.facets,
      combinedSearch: state.assets.combinedSearch
    }));
    const currentSort = config.webapp.sortBy.find(
      (e) => e.label === sortBy.value
    );
    const sort = currentSort ? [currentSort?.sort] : [defaultSortBy];

    const initialFacets = getInitialFacets(
      [...config.webapp.topFilters, ...config.webapp.sidebarFilters],
      facets
    );

    const query = buildQuery(
      {
        term: combinedSearch
      },
      initialFacets,
      sort as Array<{ [x: string]: 'asc' | 'desc' }>,
      null,
      config.webapp
    );

    const responseAssets: ResponseAssets = yield call(
      assetService.getAssets,
      query
    );

    const hits = responseAssets.hits.hits;
    const newTieBreaker = get(hits, [hits.length - 1, 'sort'], null);
    const newFacets = getFacetsFromAggregations(
      facets,
      responseAssets.aggregations,
      config.webapp.languageFacet,
      config.webapp.languageZXX
    );

    yield put(
      getAssetsSuccess(
        hits,
        responseAssets.hits.total.value,
        newFacets,
        newTieBreaker
      )
    );
  } catch (e: unknown) {
    yield put(getAssetsFailed((e as { message: string })?.message));
  }
}

export default function* facetSaga() {
  yield takeLatest(GET_ASSETS_START, getAssets);
  yield takeLatest(GET_MORE_ASSETS_START, getMoreAssets);
  yield takeLatest(RESET_FILTER, resetFilter);
  yield takeLatest(RESET_FILTERS, resetFilters);
  yield takeLatest(FACET_FILTER_CHANGED, facetFilterChanged);
  yield takeLatest(SET_SEARCH, setSearch);
  yield takeLatest(SORT_BY, sortBy);
}
