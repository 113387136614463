import { SortBy, Config } from '../../../types/logic';
import {
  Status,
  STATUS_FAILURE,
  STATUS_LOADING,
  STATUS_START,
  STATUS_SUCCESS
} from '../../../types/status';

import {
  ActionTypes,
  GET_CONFIG_FAILURE,
  GET_CONFIG_START,
  GET_CONFIG_SUCCESS
} from '../../actions/config/getConfigAction';

export type State = {
  config: Config;
  status: Status;
  error: null | string;
  defaultSortBy: SortBy;
};

const initialState: State = {
  config: {
    pdftron: {
      sdk: {
        licenseKey: null
      },
      webViewer: {
        licenseKey: 'licenseKey',
        options: {
          disableLogs: false
        }
      }
    },
    webapp: {
      sortBy: [],
      topFilters: [],
      sidebarFilters: [],
      drawerWidth: 300,
      assetTabs: [],
      languageFacet: '',
      languageZXX: ''
    }
  },
  status: STATUS_START,
  defaultSortBy: { tieBreaker: 'asc' },
  error: null
};

// eslint-disable-next-line func-names
// eslint-disable-next-line import/no-anonymous-default-export
export default function (
  state: State = initialState,
  action: ActionTypes
): State {
  switch (action.type) {
    case GET_CONFIG_START:
      return {
        ...state,
        status: STATUS_LOADING,
        config: initialState.config,
        error: initialState.error
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        config: { ...initialState.config, ...action.payload.config }
      };
    case GET_CONFIG_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        status: STATUS_FAILURE
      };

    default:
      return state;
  }
}
