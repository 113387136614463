import { Suspense, lazy, LazyExoticComponent } from 'react';

import SuspenseLoader from '../components/SuspenseLoader/SuspenseLoader';

const Loader =
  (
    Component: JSX.IntrinsicAttributes | LazyExoticComponent<() => JSX.Element>
  ) =>
  (props: JSX.IntrinsicAttributes) =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        {/* @ts-ignore */}
        <Component {...props} />
      </Suspense>
    );

// Status
const Status404 = Loader(
  lazy(() => import('../pages/Status/Status404/Status404'))
);

const statusRoutes = [
  {
    path: '',
    element: <Status404 />
  }
];

export default statusRoutes;
