import { Box, Card, Typography, Container, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HOME_PAGE_PATH } from '../../../constants/route';

function Status404() {
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="md"
      sx={{
        height: '100%'
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box textAlign="center">
          <img
            alt="404"
            height={180}
            src={`${process.env.PUBLIC_URL}/404.svg`}
          />
          <Typography
            variant="h2"
            sx={{
              my: 2
            }}
          >
            {t('decathlon_status_page_doesnt_exist')}
          </Typography>
        </Box>
        <Container maxWidth="sm">
          <Card
            sx={{
              textAlign: 'center',
              mt: 3,
              p: 4
            }}
          >
            <Button href={HOME_PAGE_PATH} variant="outlined">
              {t('decathlon_status_go_homepage')}
            </Button>
          </Card>
        </Container>
      </Box>
    </Container>
  );
}

export default Status404;
