import { getToken } from '../../auth/session';
import { QueryType, ResponseAssets } from '../../types/logic';

import api from '../api';
import {
  handleGetAssetsError,
  handleGetFileUrlError,
  handleGetFileUrlByVersionError
} from './assetHelper';

export function getAssets(query: QueryType): Promise<ResponseAssets> {
  const access_token = getToken();
  return api
    .post('/v1/elasticsearch', query, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${access_token}`
      }
    })
    .then((response) => response.data)
    .catch(handleGetAssetsError);
}

export function getFileUrl(
  id: string,
  fileKey: string
): Promise<{ url: string }> {
  const access_token = getToken();
  return api
    .get(`/v1/documents/${id}/files/${fileKey}/url`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${access_token}`
      }
    })
    .then((response) => response.data)
    .catch(handleGetFileUrlError);
}

export function getFileUrlByVersion(
  id: string,
  fileKey: string,
  version: string | number
): Promise<{ url: string }> {
  const access_token = getToken();
  return api
    .get(`/v1/documents/${id}/versions/${version}/files/${fileKey}/url`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${access_token}`
      }
    })
    .then((response) => response.data)
    .catch(handleGetFileUrlByVersionError);
}
