import ServiceError from '../ServiceError';

export function handleGetConfigError(error: unknown): ServiceError {
  let errorMessage = '';
  try {
    if ((error as { response: { status: number } }).response.status === 400)
      errorMessage = 'app.error.getConfig.not-found';
  } catch (e) {
    throw new Error('app.error.unknow');
  }
  throw new Error(errorMessage === '' ? 'app.error.unknow' : errorMessage);
}
