import { SxProps, Theme, Tooltip, Typography } from '@mui/material';

type Props = {
  children: JSX.Element;
  title: JSX.Element | string | number | HTMLElement;
  useEllipsis?: boolean;
  placement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
};
const sxStyles: Record<string, SxProps<Theme>> = {
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

function DefaultTooltip({
  children,
  title,
  useEllipsis = false,
  placement = 'bottom'
}: Props) {
  return (
    <>
      {title && title !== '' ? (
        <Tooltip arrow placement={placement} title={title}>
          {useEllipsis ? (
            <Typography component="div" sx={sxStyles.ellipsis}>
              {children}
            </Typography>
          ) : (
            children
          )}
        </Tooltip>
      ) : (
        children
      )}
    </>
  );
}

export default DefaultTooltip;
