import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  getDownloadLinkFailed,
  getDownloadLinkSuccess,
  getFileUrlByVersionFailed,
  getFileUrlByVersionSuccess,
  getFileUrlFailed,
  getFileUrlSuccess,
  getPreviewLinkFailed,
  getPreviewLinkSuccess,
  GET_DOWNLOAD_LINK_START,
  GET_DOWNLOAD_LINK_START_ACTION,
  GET_FILE_URL_BY_VERSION_START,
  GET_FILE_URL_BY_VERSION_START_ACTION,
  GET_FILE_URL_START,
  GET_FILE_URL_START_ACTION,
  GET_PREVIEW_LINK_START,
  GET_PREVIEW_LINK_START_ACTION
} from '../actions/asset/assetActions';
import * as assetService from '../../services/asset/assetService';

function* getFileUrl(action: GET_FILE_URL_START_ACTION): any {
  try {
    if (action.payload.version) {
      const response: { url: string } = yield call(
        assetService.getFileUrlByVersion,
        action.payload.id,
        action.payload.key || 'master',
        action.payload.version
      );
      yield put(getFileUrlSuccess(response.url));
    } else {
      const response: { url: string } = yield call(
        assetService.getFileUrl,
        action.payload.id,
        'preview'
      );
      yield put(getFileUrlSuccess(response.url));
    }
  } catch (e: unknown) {
    yield put(getFileUrlFailed((e as { message: string })?.message));
  }
}

function* getDownloadLink(action: GET_DOWNLOAD_LINK_START_ACTION): any {
  try {
    if (action.payload.assetVersion) {
      const response = yield call(
        assetService.getFileUrlByVersion,
        action.payload.id as string,
        'master',
        action.payload.assetVersion as number
      );
      yield put(getDownloadLinkSuccess(response.url));
    } else {
      const response: { url: string } = yield call(
        assetService.getFileUrl,
        action.payload.id as string,
        'master'
      );
      yield put(getDownloadLinkSuccess(response.url));
    }
  } catch (e: unknown) {
    yield put(getDownloadLinkFailed((e as { message: string })?.message));
  }
}

function* getPreviewLink(action: GET_PREVIEW_LINK_START_ACTION): any {
  try {
    if (action.payload.assetVersion) {
      const response = yield call(
        assetService.getFileUrlByVersion,
        action.payload.id as string,
        'master',
        action.payload.assetVersion as number
      );
      yield put(getPreviewLinkSuccess(response.url));
    } else {
      const response: { url: string } = yield call(
        assetService.getFileUrl,
        action.payload.id as string,
        'master'
      );
      yield put(getPreviewLinkSuccess(response.url));
    }
  } catch (e: unknown) {
    yield put(getPreviewLinkFailed((e as { message: string })?.message));
  }
}

function* getFileUrlByVersion(
  action: GET_FILE_URL_BY_VERSION_START_ACTION
): any {
  try {
    const response: { url: string } = yield call(
      assetService.getFileUrlByVersion,
      action.payload.id,
      'preview',
      action.payload.version
    );

    yield put(
      getFileUrlByVersionSuccess(
        action.payload.id,
        action.payload.version,
        response.url
      )
    );
  } catch (e: unknown) {
    yield put(getFileUrlByVersionFailed((e as { message: string })?.message));
  }
}

export default function* facetSaga() {
  yield takeLatest(GET_FILE_URL_START, getFileUrl);
  yield takeLatest(GET_DOWNLOAD_LINK_START, getDownloadLink);
  yield takeEvery(GET_FILE_URL_BY_VERSION_START, getFileUrlByVersion);
  yield takeLatest(GET_PREVIEW_LINK_START, getPreviewLink);
}
