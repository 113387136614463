import ServiceError from '../ServiceError';

export function handleGetAssetsError(error: unknown): ServiceError {
  let errorMessage = '';
  try {
    if ((error as { response: { status: number } }).response.status === 400)
      errorMessage = 'app.error.getAssets.not-found';
  } catch (e) {
    throw new Error('app.error.unknow');
  }
  throw new Error(errorMessage === '' ? 'app.error.unknow' : errorMessage);
}

export function handleGetAssetError(error: unknown): ServiceError {
  let errorMessage = '';
  try {
    if ((error as { response: { status: number } }).response.status === 400)
      errorMessage = 'app.error.getAsset.not-found';
  } catch (e) {
    throw new Error('app.error.unknow');
  }
  throw new Error(errorMessage === '' ? 'app.error.unknow' : errorMessage);
}
export function handleGetAssetVersionError(error: unknown): ServiceError {
  let errorMessage = '';
  try {
    if ((error as { response: { status: number } }).response.status === 400)
      errorMessage = 'app.error.getAssetVersion.not-found';
  } catch (e) {
    throw new Error('app.error.unknow');
  }
  throw new Error(errorMessage === '' ? 'app.error.unknow' : errorMessage);
}

export function handleGetFileUrlError(error: unknown): ServiceError {
  let errorMessage = '';
  try {
    if ((error as { response: { status: number } }).response.status === 400)
      errorMessage = 'app.error.getFileUrl.not-found';
  } catch (e) {
    throw new Error('app.error.unknow');
  }
  throw new Error(errorMessage === '' ? 'app.error.unknow' : errorMessage);
}

export function handleGetFileUrlByVersionError(error: unknown): ServiceError {
  let errorMessage = '';
  try {
    if ((error as { response: { status: number } }).response.status === 400)
      errorMessage = 'app.error.getFileUrlByVersion.not-found';
  } catch (e) {
    throw new Error('app.error.unknow');
  }
  throw new Error(errorMessage === '' ? 'app.error.unknow' : errorMessage);
}

export function handleGetAssetVersionsError(error: unknown): ServiceError {
  let errorMessage = '';
  try {
    if ((error as { response: { status: number } }).response.status === 400)
      errorMessage = 'app.error.getAssetVersions.not-found';
  } catch (e) {
    throw new Error('app.error.unknow');
  }
  throw new Error(errorMessage === '' ? 'app.error.unknow' : errorMessage);
}
