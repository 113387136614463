import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import rootReducer, { StateAccessorType } from '../reducers/index';
import rootSaga from '../sagas/root';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

export const history = createBrowserHistory();
history.listen(() => {
  window.scrollTo(0, 0);
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    StateAccessorType.ASSETS_REDUCER,
    StateAccessorType.ASSET_REDUCER,
    StateAccessorType.CONFIG_REDUCER
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type StateType = ReturnType<typeof persistedReducer>;

let sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  sagaMiddleware
];

const store = configureStore({
  reducer: persistedReducer,
  middleware
});

export const persistor = persistStore(store as any);

export default store;

sagaMiddleware.run(rootSaga);
