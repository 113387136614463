import { Config } from '../../../types/logic';

export const GET_CONFIG_START = 'GET_CONFIG_START';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';

export type GET_CONFIG_START_ACTION = {
  type: typeof GET_CONFIG_START;
  payload: {};
};

export type GET_CONFIG_SUCCESS_ACTION = {
  type: typeof GET_CONFIG_SUCCESS;
  payload: {
    config: Config;
  };
};

export type GET_CONFIG_FAILURE_ACTION = {
  type: typeof GET_CONFIG_FAILURE;
  payload: {
    error: string;
  };
};

export function getConfig(): GET_CONFIG_START_ACTION {
  return {
    type: GET_CONFIG_START,
    payload: {}
  };
}

export function getConfigSuccess(config: Config): GET_CONFIG_SUCCESS_ACTION {
  return {
    type: GET_CONFIG_SUCCESS,
    payload: { config }
  };
}

export function getConfigFailed(error: string): GET_CONFIG_FAILURE_ACTION {
  return {
    type: GET_CONFIG_FAILURE,
    payload: { error }
  };
}

export type ActionTypes =
  | GET_CONFIG_START_ACTION
  | GET_CONFIG_SUCCESS_ACTION
  | GET_CONFIG_FAILURE_ACTION;
